<template>
  <div :class="$store.state.tyPhone == 'ios'?'personal iosList':'personal'">
    <div class="personalTop" @click="navGo">
      <img :src="id == 1?grpeople:frpeople" alt="">
    </div>
    <div class="personalMiddle">
      <img alt="" v-for="i in btnList" :key="i.id" :src="i.recommendPhoto" @click="btnServer(i)">
    </div>
    <div class="personalUnder">
      <van-cell v-for="i in cellList" :key="i.id" :title="i.columnName" is-link @click="cellBtn(i)">
        <template #icon>
          <img class="icon" :src="i.columnIcon" alt="">
        </template>
      </van-cell>
    </div>
    <goPage v-if="$store.state.tyPhone == 'ios'"></goPage>
  </div>
</template>

<script>
import {getChildSceneColumn,getSceneRecList,getSceneContentByColumnId} from '@/api/home.js'
export default {
  name: 'personal',
  data () {
    return {
      btnList:[],
      cellList:[],
      id:1,
      grpeople:require('@/assets/gerenTop.png'),
      frpeople:require('@/assets/farenTop.png')
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init(){
      let id = this.$route.query.id
      this.id = this.$route.query.id
      if(id == 1){
        if(this.$store.state.jxbApp){
          window.location.href=`ssptitle://个人场景式服务`
        }else{
          document.title = '个人场景式服务'
        }
      }else{
        if(this.$store.state.jxbApp){
          window.location.href=`ssptitle://法人场景式服务`
        }else{
          document.title = '法人场景式服务'
        }
      }
      const data = await getSceneRecList({
        pageNumber: 1,
        pageSize: 3,
        parentId: id
      })
      this.btnList = data.data.data.rows
      const res = await getChildSceneColumn({
        "pageNumber": 1,
        "pageSize": 90071992,
        "parentId": id
      })
      this.cellList = res.data.data.rows
    },
    async btnServer(res){
      
      if(res.columnType == 1){
        if(res.columnAttr == 1){
          this.$store.commit('setTopTittle',res.columnName)
          this.$router.push({path:'/personDetail',query:{columnId:res.id}})
        }else{
          localStorage.setItem('tittle',res.columnName)
          const data = await getSceneContentByColumnId({
            "columnId": res.id,
            "pageNumber": 1,
            "pageSize": 1,
            "title": ""
          })
          let typeId = data.data.data.rows[0]
          if(typeId.detailType == 1){
            if(typeId.type == 1){
              this.$router.push({path:"/scContent",query:{typeId:1,id:typeId.id}})
            }else if(typeId.type == 2){
              this.$router.push({path:"/scContent",query:{typeId:2,id:typeId.id}})
            }else{
              this.$router.push({path:"/scContent",query:{typeId:3,id:typeId.id}})
            }
          }else{
            this.$router.push({path:"/scContent",query:{typeId:4,id:typeId.id}})
          }
        }
        
      }else if(res.columnType == 2){
        this.$router.push({path:'/oneThing',query:{columnId:res.id,id:this.$route.query.id}})
      }else{
        location.href = res.columnLink
      }
    },
    async cellBtn(res){
      
      if(res.columnType == 1){
        if(res.columnAttr == 1){
          this.$store.commit('setTopTittle',res.columnName)
          this.$router.push({path:'/personDetail',query:{columnId:res.id}})
        }else{
          localStorage.setItem('tittle',res.columnName)
          const data = await getSceneContentByColumnId({
            "columnId": res.id,
            "pageNumber": 1,
            "pageSize": 1,
            "title": ""
          })
          if(data.data.data.rows.length>0){
            let typeId = data.data.data.rows[0]
            if(typeId.detailType == 1){
              if(typeId.type == 1){
                this.$router.push({path:"/scContent",query:{typeId:1,id:typeId.id}})
              }else if(typeId.type == 2){
                this.$router.push({path:"/scContent",query:{typeId:2,id:typeId.id}})
              }else{
                this.$router.push({path:"/scContent",query:{typeId:3,id:typeId.id}})
              }
            }else{
              this.$router.push({path:"/scContent",query:{typeId:4,id:typeId.id}})
            }
          }else{
            this.$router.push({path:"/err"})
          }
        }
        
      }else if(res.columnType == 2){
        this.$store.commit('setTopTittle',res.columnName)
        this.$router.push({path:'/oneThing',query:{columnId:res.id,id:this.$route.query.id}})
      }else{
        location.href = res.columnLink
      }
    },
    navGo(){
      let id = this.$route.query.id
      lightAppJssdk.weex.openWeexVc({
          title : '南开区',//顶部导航栏标题
          url : `https://jxb.tj.gov.cn/jmportalpub//jmportal_files/offlinePackage/3/1.2.2/JMP_JIM/JMP_JIM/businessModule/japply/matter.js?isWq=5&type=${id}`, //weex页面地址
          success: function(data) {
            console.log(data);
          },
          fail: function(data) {
            console.log('falied');
          }
        });
    }
  },

}
</script>

<style lang="less" scoped>
@import './personal.less';
.iosList{
   padding-bottom: 1.778rem;
}
</style>